// Background
.bg-light,
.bg-secondary,
.section.section-muted {
  background-color: $light !important;
}
// customer satisfaction bg
// TODO: change the css when the customer satisfaction container receive an ID or Class to properly identificate the container
#main > .bg-primary {
  background-color: $secondary !important;
  .bg-primary {
    background-color: $secondary !important;
  }
}

// Card layout
.card {
  .card-body {
    .card-title,
    .card-title a {
      color: $primary;
    }

    .card-text {
      color: $secondary-text;
    }

    .categoryicon-top .icon,
    .categoryicon-top .text,
    .categoryicon-top .data {
      color: $secondary;
      fill: $secondary;
    }
  }

  .etichetta .icon,
  .etichetta {
    color: $secondary;
    fill: $secondary;
  }

  .flag-icon {
    background-color: $secondary;
  }

  .read-more {
    &:hover {
      color: $link-hover-color;
    }

    .icon {
      fill: $primary;
    }
  }

  &.card-teaser .card-body .card-title {
    color: $primary;
  }
}

.bg-primary {
  .card .card-body {
    color: $primary-text;

    .categoryicon-top .data {
      color: $primary-text;
      fill: $primary-text;
    }
  }
}

// DraftJs
.callout-bg,
.callout-bg a {
  color: $primary-text;
}

// Buttons
.btn.btn-secondary {
  color: $primary-text;
}

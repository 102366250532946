.block.form {
  .form-group input,
  .form-group optgroup,
  .form-group textarea,
  label,
  .bootstrap-select-wrapper > label,
  .react-select__control {
    color: $primary;
  }
}

.block.contacts {
  .bg-primary {
    .link-button {
      a.btn-tertiary {
        color: $primary-text !important;
        background-color: $secondary;
        border-color: $secondary;

        &:hover {
          background-color: darken($secondary, 7.5%);
        }
      }
    }

    .card-body {
      color: $secondary-text;
    }
  }
}

//site
$primary: #3d3d3d;
$primary-h: 0 !default;
$primary-s: 0 !default;
$primary-b: 24 !default;

$secondary: #707070;
$tertiary: #3d3d3d;

// Text
$primary-text: #fff;
$secondary-text: #000;

// Link
$site-link-color: $primary;
$card-link-color: $primary;
$link-color: $primary;
$link-hover-color: darken($primary, 20);

// Header
$header-center-bg-color: #707070;
$site-header-slim-bg-color: $tertiary;
$header-navbar-bg-color: #fadc4f;

// Footer
$footer-bg-color: #3d3d3d;
$footer-small-bg-color: #000;

// Background
$light: lighten($secondary, 50);

// Form
$select-label-color: $primary;

// GDRP-BANNER RELATED
// $gdpr-accept-all: #005700 !default;
// $gdpr-toggle-checked: #005700 !default;
// $gdpr-toggle: #b22515 !default;
// $gdpr-toggle-border: #000 !default;
// $gdpr-focus-color: #ff9800 !default;
// $gdpr-link-color: #004285 !default;
// $gdpr-focus-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
//   0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $gdpr-focus-color !default;

// $header-slim-bg-color: $primary-dark;

//$headings-color: $primary;
//$link-color: hsl(210deg, 100%, 40%);

.navbar.has-megamenu li.nav-item.active .dropdown a.nav-link {
  border-bottom-color: $secondary-text;
}
.navbar .navbar-collapsable .navbar-nav li a.nav-link {
  color: $secondary-text;

  .icon.megamenu-toggle-icon {
    fill: $secondary-text;
  }
}

&:not(.subsite) .custom-navbar-toggler svg.icon {
  fill: $primary-text;
}

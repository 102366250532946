@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-navbar-wrapper {
    background-color: $header-navbar-bg-color;
  }

  .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-brand-wrapper
    a
    .icon {
    height: 100px;
  }
}

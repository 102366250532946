// menu laterale pagine interne
.link-list-wrapper {
  &.menu-link-list {
    h3,
    h4 {
      &:after {
        background-color: $secondary;
      }
    }
  }
}

.it-footer {
  &-small-prints {
    background-color: $footer-small-bg-color;
  }

  &-main {
    background-color: $footer-bg-color;

    .it-brand-wrapper {
      a .icon {
        width: auto;
        height: 85px;
        margin-right: 1rem;
        margin-bottom: 0;

        img {
          height: 100%;
        }
      }
    }
  }
}

.block.highlitedContent {
  .bg-primary {
    .card .card-body {
      .category-top,
      .card-title a,
      .card-text,
      .read-more {
        color: $primary-text !important;
      }

      .read-more .icon {
        fill: $primary-text;
      }

      .categoryicon-top .icon,
      .categoryicon-top .text {
        color: $primary-text;
        fill: $primary-text;
      }
    }
  }
}

.block.hero {
  .hero-body {
    background-color: $light;

    h1 {
      color: $primary;
    }

    .read-more svg.icon {
      fill: $tertiary;
    }
  }
}

.color-list-widget .button.secondary,
.color-list-widget .button.secondary.active {
  background-color: $light;
}

.ui.primary.button {
  background-color: $primary;
  font-family: $font-family-sans-serif;

  &:hover {
    background-color: $link-hover-color;
  }
}
